import {logo} from './logo-fawlts-extended'
import {logoFawlts} from "./logo-fawlts";
import {logoFawltsExtendedNegative} from "./logo-fawlts-extended-negative";

import {
   cibSkype,
   cibFacebook,
   cibTwitter,
   cibLinkedin,
   cibFlickr,
   cibTumblr,
   cibXing,
   cibGithub,
   cibStackoverflow,
   cibYoutube,
   cibDribbble,
   cibInstagram,
   cibPinterest,
   cibVk,
   cibYahoo,
   cibBehance,
   cibReddit,
   cibVimeo,
   cibCcMastercard,
   cibCcVisa,
   cibStripe,
   cibPaypal,
   cibGooglePay,
   cibCcAmex
} from '@coreui/icons'
import {
   cifUs,
   cifBr,
   cifIn,
   cifFr,
   cifEs,
   cifPl
} from '@coreui/icons'
import {
   cilAlignCenter,
   cilAccountLogout,
   cilAlignLeft,
   cilAlignRight,
   cilApplicationsSettings,
   cilArrowRight,
   cilArrowTop,
   cilAsterisk,
   cilAsteriskCircle,
   cilBan,
   cilBasket,
   cilBell,
   cilBold,
   cilBolt,
   cilBookmark,
   cilBriefcase,
   cilCalculator,
   cilCalendar,
   cilCaretRight,
   cilCloudDownload,
   cilChartPie,
   cilCheck,
   cilChevronBottom,
   cilChevronLeft,
   cilChevronRight,
   cilChevronTop,
   cilCircle,
   cilCheckCircle,
   cilCode,
   cilCommentSquare,
   cilCreditCard,
   cilCursor,
   cilCursorMove,
   cilDataTransferDown,
   cilDrop,
   cilDollar,
   cilEnvelopeClosed,
   cilEnvelopeLetter,
   cilEnvelopeOpen,
   cilEuro,
   cilGlobeAlt,
   cilGrid,
   cilFile,
   cilFlower,
   cilFullscreen,
   cilFullscreenExit,
   cilGraph,
   cilHome,
   cilInbox,
   cilIndentDecrease,
   cilIndentIncrease,
   cilInputPower,
   cilItalic,
   cilJustifyCenter,
   cilJustifyLeft,
   cilLaptop,
   cilLayers,
   cilLightbulb,
   cilList,
   cilListNumbered,
   cilListRich,
   cilLocationPin,
   cilLockLocked,
   cilMagnifyingGlass,
   cilMap,
   cilMoon,
   cilNotes,
   cilOptions,
   cilPaperclip,
   cilPaperPlane,
   cilPencil,
   cilPeople,
   cilPlus,
   cilPhone,
   cilPrint,
   cilPuzzle,
   cilSave,
   cilScrubber,
   cilSettings,
   cilShare,
   cilShareAll,
   cilShareBoxed,
   cilShieldAlt,
   cilSitemap,
   cilSpa,
   cilSpeech,
   cilSpeedometer,
   cilSpreadsheet,
   cilStar,
   cilSun,
   cilAddressBook,
   cilTags,
   cilTask,
   cilTrash,
   cilUnderline,
   cilUser,
   cilUserFemale,
   cilUserFollow,
   cilUserUnfollow,
   cilX,
   cilXCircle,
   cilSchool,
   cilInstitution,
   cilWarning,
   cilBuilding,
   cilAt,
   cilWc
} from '@coreui/icons'

export const icons = Object.assign({}, {
   logo,
   logoFawlts,
   logoFawltsExtendedNegative
}, {
   cilAlignCenter,
   cilAddressBook,
   cilAccountLogout,
   cilAlignLeft,
   cilAlignRight,
   cilApplicationsSettings,
   cilArrowRight,
   cilArrowTop,
   cilAsterisk,
   cilAsteriskCircle,
   cilBan,
   cilBasket,
   cilBell,
   cilBold,
   cilBolt,
   cilBriefcase,
   cilBookmark,
   cilCalculator,
   cilCalendar,
   cilCaretRight,
   cilCloudDownload,
   cilChartPie,
   cilCheck,
   cilChevronBottom,
   cilChevronLeft,
   cilChevronRight,
   cilChevronTop,
   cilCircle,
   cilCheckCircle,
   cilCode,
   cilCommentSquare,
   cilCreditCard,
   cilCursor,
   cilCursorMove,
   cilDataTransferDown,
   cilDrop,
   cilDollar,
   cilEnvelopeClosed,
   cilEnvelopeLetter,
   cilEnvelopeOpen,
   cilEuro,
   cilGlobeAlt,
   cilGrid,
   cilFile,
   cilFlower,
   cilFullscreen,
   cilFullscreenExit,
   cilGraph,
   cilHome,
   cilInbox,
   cilIndentDecrease,
   cilIndentIncrease,
   cilInputPower,
   cilItalic,
   cilJustifyCenter,
   cilJustifyLeft,
   cilLaptop,
   cilLayers,
   cilLightbulb,
   cilList,
   cilListNumbered,
   cilListRich,
   cilLocationPin,
   cilLockLocked,
   cilMagnifyingGlass,
   cilMap,
   cilMoon,
   cilNotes,
   cilOptions,
   cilPaperclip,
   cilPaperPlane,
   cilPencil,
   cilPeople,
   cilPlus,
   cilPhone,
   cilPrint,
   cilPuzzle,
   cilSave,
   cilScrubber,
   cilSettings,
   cilShare,
   cilShareAll,
   cilShareBoxed,
   cilShieldAlt,
   cilSitemap,
   cilSpa,
   cilSpeech,
   cilSchool,
   cilSpeedometer,
   cilSpreadsheet,
   cilStar,
   cilSun,
   cilTags,
   cilTask,
   cilTrash,
   cilUnderline,
   cilUser,
   cilUserFemale,
   cilUserFollow,
   cilUserUnfollow,
   cilX,
   cilXCircle,
   cilWarning,
   cilInstitution,
   cilBuilding,
   cilAt,
   cilWc
}, {
   cifUs,
   cifBr,
   cifIn,
   cifFr,
   cifEs,
   cifPl
}, {
   cibSkype,
   cibFacebook,
   cibTwitter,
   cibLinkedin,
   cibFlickr,
   cibTumblr,
   cibXing,
   cibGithub,
   cibStackoverflow,
   cibYoutube,
   cibDribbble,
   cibInstagram,
   cibPinterest,
   cibVk,
   cibYahoo,
   cibBehance,
   cibReddit,
   cibVimeo,
   cibCcMastercard,
   cibCcVisa,
   cibStripe,
   cibPaypal,
   cibGooglePay,
   cibCcAmex
})
