import React, {useState} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import PrivateRoute from './PrivateRoute';
import {AuthContext} from "./context/auth";
import './scss/style.scss';

const loading = (
   <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"/>
   </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));

// Query
const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         refetchOnWindowFocus: false
      }
   }
})

function App() {
   const existingTokens = JSON.parse(localStorage.getItem("tokens"));
   const [authTokens, setAuthTokens] = useState(existingTokens);

   const setTokens = (data) => {
      localStorage.setItem("tokens", JSON.stringify(data));
      setAuthTokens(data);
   }

   return (
      <QueryClientProvider client={queryClient}>
         <AuthContext.Provider value={{authTokens, setAuthTokens: setTokens}}>
            <HashRouter>
               <React.Suspense fallback={loading}>
                  <Switch>
                     <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
                     <PrivateRoute path="/" name="Home" component={props => <TheLayout {...props}/>}/>
                  </Switch>
               </React.Suspense>
            </HashRouter>
         </AuthContext.Provider>
      </QueryClientProvider>
   );
}

export default App;
