import {CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../../context/auth";


const AuthModal = () => {
   const [modal, setModal] = useState(true);
   let history = useHistory();
   const {setAuthTokens} = useAuth();

   const logOut = () => {
      localStorage.setItem("tokens", null);
      setAuthTokens(null);
   }

   return (
      <CModal
         show={modal}
         onClose={setModal}
      >
         <CModalHeader>
            <CModalTitle>Sessione scaduta</CModalTitle>
         </CModalHeader>
         <CModalBody>
            La tua sessione è scaduta, verrai reindirizzato alla pagina di login
            per effettuare nuovamente l'accesso.
         </CModalBody>
         <CModalFooter>
            <CButton
               color="primary"
               onClick={() => {
                  setModal(false);
                  logOut();
                  history.push('/login');
               }}>OK
            </CButton>
         </CModalFooter>
      </CModal>
   )
}

export default AuthModal;
