import React from "react";
import {Route, Redirect} from "react-router-dom";
import {useAuth} from "./context/auth";
import AuthModal from "./views/notifications/modals/AuthModal";
import { useCookies } from 'react-cookie';


function PrivateRoute({component: Component, ...rest}) {
   const {authTokens} = useAuth();
   const [cookies, setCookie, removeCookie] = useCookies(['tokenExpiration']);

   return (
      <Route
         {...rest}
         render={props => {
            // Check if token is present
            if (authTokens) {
               // Check if token is expired
               if (cookies['tokenExpiration'] > (Date.now() / 1000 | 0)) {
                  return <Component {...props} />
               } else {
                  return <AuthModal />
               }
            }
            return <Redirect to="/login"/>
         }}
      />
   );
}

export default PrivateRoute;
